import * as React from 'react';
import { set, useForm } from 'react-hook-form';
import {useNavigate,useParams} from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import './loguin.css'
import removeCokies from './removeCokies'
import seteaCokie from './seteaCokie';
import { Spinner } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { useState } from 'react';
import swal from 'sweetalert2'

const cookies=new Cookies();

const URI='https://apps.isepsantafe.edu.ar/inscriptos/RE/'
const UROLD='https://apps.isepsantafe.edu.ar/inscriptos/'
function Loguin() {
  const [espera,setEspera]=React.useState(false)
  const activa=()=>setEspera(true)
  const desactiva=()=>setEspera(false)
  const [abierto,setAbierto]=useState(true);
  
  const estadoInscripcion=async()=>{
   
    try {
 
     await axios.post(URI+'funciones').then(x=>setAbierto(x.data[0].status)).then(cookies.set('Abiertas',abierto, {path:"/"}));

      } catch (error) {
       swal("Errores en el servidor intente mas tarde!")
      }
    }
  estadoInscripcion();

  

 const navitate = useNavigate()
 removeCokies();
 
 
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({});



  const getUsuarioById=async (cosa)=>{ 
    try {
         activa()
         
         const respuestaInicial= await axios.post(UROLD+"consulta/"+cosa.dni)
if(respuestaInicial.data){
swal.fire(
{
icon: "info",

title: "USTED YA SE ENCUENTRA INSCRIPTO EN EL PROCESO 2024-2025 el cual aún no ha finalizado. ",
text: "Esta reapertura esta dirigida a personas que aun no se anotaron en este proceso!...",
footer: '<a href="https://www.isepsantafe.edu.ar/index.php/noticias/reapertura-de-pre-inscripcion-ingreso-2024-tecnico-superior-en-seguridad-publica-y-ciudadana" target="_blank" rel="noopener noreferrer">Por que no puedo anotarme (REQUISITOS)?</a>',


}


)
console.log(respuestaInicial.data)
desactiva()
}else{
  const res= await axios.post(URI+"consulta/"+cosa.dni)
  if(!res.data){
    if(abierto==="true"){
      cookies.set('Abiertas',abierto, {path:"/"})
      cookies.set('dni',cosa.dni, {path:"/"})
      cookies.set('email',cosa.email, {path:"/"})
      cookies.set('telefono_1',cosa.telefono_1, {path:"/"})
      desactiva()
      navitate('/new')
    }else{
      swal.fire({
        icon: "warning",

        title: "Inscripciones Cerradas! ",
        text: "Usted no se encuentra inscripto para imprimir su legajo",
        footer: '<a href="https://www.isepsantafe.edu.ar/index.php/noticias/reapertura-de-pre-inscripcion-ingreso-2024-tecnico-superior-en-seguridad-publica-y-ciudadana" target="_blank" rel="noopener noreferrer">Por que no puedo anotarme (REQUISITOS)?</a>',
    }); 
      desactiva()
    }
    
    }
    else{
    var respuesta=res.data; //sustituyo el valor data[0] por una variable mas facil de manejar
    seteaCokie(respuesta)
    cookies.set('Abiertas',abierto, {path:"/"})
    if(respuesta.email===cosa.email||respuesta.telefono_1===cosa.telefono_1){
      
      seteaCokie(respuesta)
      desactiva()
      navitate('/inscripto')
    }else{
   
      swal.fire("credenciales invalidas Usuario Registrado pero bajo otro correo o numero de telefono")
      desactiva()
    }

  }
}
    
    } catch (error) {
      desactiva()
      console.log("error al cargar")
    }
    
}
return (
    <div>

      <div className='form__loguin_ingreso'>
       
      <div className='imagen_loguin'>  
        </div>
       <div>
    <form className="form_use" onSubmit={handleSubmit(getUsuarioById)}>
        <h1 className="form__title_Important">Técnico Superior en Seguridad Pública y Ciudadana <h3 style={{textAlign:"center"}}>Reapertura de Pre Inscripción ciclo 2024-2025  </h3></h1>
       
       
        {abierto=="true"?(<h3 style={{textAlign:"center",color:"green"}}>"Inscripciones Abiertas"</h3>):(<h3 style={{textAlign:"center",color:"red"}}>"Inscripciones Cerradas - Imprima su legajo"</h3>)}

       
        {espera?(<div style={{textAlign:'center'}}> <Spinner animation="grow" size="xl" /></div>):""}
      <div className="form__container">
      
          <div className="form__group">
          {errors.dni?.type === 'required' && (
            <span className="aviso-campos-loguin">Para comenzar debe colocar su numero de DNI</span>
          )}
          {errors.dni?.type === 'pattern' && (
            <span className="aviso-campos-loguin">
              Formato invalido solo numeros sin . ni -{' '}
            </span>
          )}
          {errors.dni?.type === 'maxLength' && (
            <span className="aviso-campos-loguin">Formato invalido solo 8 numeros </span>
          )}
          {errors.dni?.type === 'minLength' && (
            <span className="aviso-campos-loguin">Formato invalido solo 8 numeros </span>
          )}
          <input
            type="text"
            placeholder=" "
            className="form__input"
            id="dni"
            {...register('dni', {
              required: true,
              pattern: /^-?\d+(?:,\d+)?(?:[Ee][-+]?\d+)?$/i,
              maxLength: 8,
              minLength: 8,
            })}
          />
            <label for="dni" className="form__label">
              DNI
            </label>
            <span className="form__line"></span>
        </div>
          <div className="form__group">
            {errors.email?.type === 'required' && (
              <span className="aviso-campos-loguin">Indique una direccion de correo electrónico</span>
            )}
            {errors.email?.type === 'pattern' && (
              <span className="aviso-campos-loguin">
                Debe tener una direccion de correo valida!!!
              </span>
            )}
            <input
              className="form__input"
              id="email"
              type="text"
              placeholder=" "
              autoComplete='true'
              {...register('email', {
                required: true,
                pattern:
                  /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i,
              })}
            /> 
            <label for="email" className="form__label">
            Correo Electrónico
          </label>
          <span className="form__line"></span>

          
          </div>
          


          <div className="form__group">
            {errors.telefono_1?.type === 'required' && (
              <span className="aviso-campos-loguin">Indique un numero de telefono con caracteristica</span>
            )}
            {errors.telefono_1?.type === 'maxLength' && (
              <span className="aviso-campos-loguin">
                Debe tener un numero de telefono valido es muy largo
              </span>
            )}
                        {errors.telefono_1?.type === 'minLength' && (
              <span className="aviso-campos-loguin">
                Debe tener un numero de telefono valido es muy corto
              </span>
            )}
            <input
              className="form__input"
              id="telefono_1"
              type="number"
              placeholder=" "
              autoComplete='true'
              {...register('telefono_1', {
                required: true,
                maxLength: 15,
                minLength: 10,
               
              })}
            /> 
            <label for="email" className="form__label">
            Numero de Telefono
          </label>
          <span className="form__line"></span>

          
          </div>



          
          <div >
            <p className='condiciones'>
              Acepto los{' '}
              <a href="https://www.isepsantafe.edu.ar/index.php/noticias/reapertura-de-pre-inscripcion-ingreso-2024-tecnico-superior-en-seguridad-publica-y-ciudadana" target="_blank" rel="noopener noreferrer"className="form__link">
                terminos de preinscripcion que contemplados en el REGLAMENTO DE APTITUD PSICOFISICA PARA EL INGRESO O REINCORPORACION Y ACTIVIDADES DE FORMACION Y CAPACITACION PROFESIONAL EN EL INSTITUTO DE SEGURIDAD PÚBLICA DE LA PROVINCIA DE SANTA FE y los REQUISITOS) {' '}
              </a> y confirmo entender que el presente paso de preinscripcion <strong>constituye una declaracion Jurada, por lo que todos sus datos son verdaderos y fidedignos, que cualquier adulteración o contenido apócrifo dará como resultado la inmediata separación del proceso de selección</strong> y que cumplida la etapa de preinscripcion, se publicará en la pagina Web del Instituto de Seguridad Pública de la Provincia de Santa Fe, las fechas, turnos y condiciones para poder completar el proceso de inscripción, lo cual se efectivizará una vez que presente la totalidad de la documental requerida.
             
              <input
              type="checkbox"
              id="acepta"
              style={{marginLeft:"5px",fontSize:"20px"}}
              className="form__input-checkbox"
              placeholder=" "{...register('acepta', {
                required: true,
              })}
            />
 {errors.acepta?.type === 'required' && (
              <span className="aviso-checkbox">Debe aceptar los terminos de Preinscripción</span>
            )}      
            </p>
           
            <div className="form__footer">
          <input style={{alignContent:"center"}} type="submit" className="form__submit" value="Continuar" />
          <h4 className='form__footer'>DTyD 2024</h4>
        </div>

          </div>
       
          
         
        </div>
      </form>
</div>
         </div>
    </div>
  );
}
export default Loguin;
